import PropTypes from 'prop-types';
import React from 'react';

export default function BlogPostPre({ children }) {
  if (typeof children === 'object' && children.props && typeof children.props.children === 'string' && /instagram-media/.test(children.props.children)) {
    return (
      <>
        {children}
      </>
    );
  }
  return (
    <pre>
      {children}
    </pre>
  );
}

BlogPostPre.propTypes = {
  children : PropTypes.node.isRequired
};
