import PropTypes from 'prop-types';
import React from 'react';

export default function BlogPostTextArea({ children, ...props }) {
  return (
    <textarea {...props} defaultValue={children} />
  );
}

BlogPostTextArea.propTypes = {
  children : PropTypes.node
};

BlogPostTextArea.defaultProps = {
  children : ''
};
