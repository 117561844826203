// extracted by mini-css-extract-plugin
export var base = "BlogPost-module--base--3HHWV";
export var rightAlignedContainer = "BlogPost-module--rightAlignedContainer--2Vq5J";
export var rightAlignedContainerSmall = "BlogPost-module--rightAlignedContainerSmall--3AJ1S";
export var leftAlignedContainer = "BlogPost-module--leftAlignedContainer--1vrMP";
export var blogContainer = "BlogPost-module--blogContainer--3ywoh BlogPost-module--base--3HHWV";
export var container = "BlogPost-module--container--2dLNV BlogPost-module--base--3HHWV";
export var largeContainer = "BlogPost-module--largeContainer--6rBMu BlogPost-module--base--3HHWV";
export var mobileSmallPadding = "BlogPost-module--mobileSmallPadding--3hwk2";
export var fullWidthMobile = "BlogPost-module--fullWidthMobile--2APj_";
export var gridWithSidebar = "BlogPost-module--gridWithSidebar--2HFd4";
export var noMaxWidth = "BlogPost-module--noMaxWidth--1iFKG";
export var relatedArticles = "BlogPost-module--relatedArticles--3dleT";
export var relatedArticlesInner = "BlogPost-module--relatedArticlesInner--1JTi_ BlogPost-module--blogContainer--3ywoh BlogPost-module--base--3HHWV";
export var divider = "BlogPost-module--divider--5GDrY";