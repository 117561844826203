import { selectAll } from 'hast-util-select';

/**
 * Checks the schematag for the list-item schematag type and adds to list schema object
 *
 * @method generateListSchema
 *
 * @param {object} post json representation of the blog post
 *
 * @returns {object} list schema object with all required properties
 */
export default function generateListSchema(post) {
  let listSchema = {
    '@context'      : 'http://schema.org',
    '@type'         : 'ItemList',
    itemListElement : []
  };
  const { htmlAst } = post.body.childMarkdownRemark;
  const schemaTags = selectAll('schematag', htmlAst);

  schemaTags.forEach((tag) => {
    const { properties } = tag;
    const text = tag.children[0].value;
    const tagType = properties.type;
    let listInfo = {};
    if (tagType === 'list-item') {
      const {
        name,
        image,
        url
      } = properties;

      if (name && text && url) {
        listInfo = {
          '@type'  : 'ListItem',
          position : listSchema.itemListElement.length + 1,
          item     : {
            '@type' : 'HowToTip',
            name,
            text,
            url
          }
        };
        if (image) {
          listInfo.item.image = image;
        }
        listSchema.itemListElement.push(listInfo);
      }
    }
  });

  listSchema = listSchema.itemListElement.length > 0 ? listSchema : null;

  return listSchema;
}
