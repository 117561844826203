function isCTA(element) {
  const { props, type } = element;
  return !!(type !== 'a' && props && props.href);
}

function isLinkedImage(children) {
  const firstChild = children[0];
  const isLink = firstChild && firstChild.props && firstChild.props.href;

  if (isLink) {
    const secondChild = firstChild.props.children && firstChild.props.children.length === 1 && firstChild.props.children[0];
    return !!(secondChild && secondChild.props && secondChild.props.className === 'gatsby-resp-image-wrapper');
  }

  return false;
}

/**
 * Checks to see if passed in children should not be wrapped in paragraph tag
 *
 * @method isNotParagraphWrappedElement
 *
 * @param {Array} children React elements
 *
 * @returns {Boolean} Are the passed in children not supposed to be wrapped by p tag
 */
export default function isNotParagraphWrappedElement(children) {
  return children.length === 1 && (isCTA(children[0]) && !isLinkedImage(children));
}
