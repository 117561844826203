const delimiterRegex = /\s*\|\s*/;

/**
 * Searches a string for "|" delimiters and creates an array
 *
 * @method parseList
 *
 * @param {string} list of items, separated by "|"
 *
 * @returns {array} array of items
 *
 * @example
 *    const list = 'frying pan|spatula|stove';
 *    parseList('HowToTool', list);
 */

export default function parseList(schemaType, list) {
  const schemaList = [];
  const listItems = list.split(delimiterRegex).filter((item) => !!item);
  for (let i = 0; i < listItems.length; i += 1) {
    schemaList.push({
      '@type' : schemaType,
      name    : listItems[i]
    });
  }
  return schemaList;
}
