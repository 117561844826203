import PropTypes from 'prop-types';
import React from 'react';

import { img, wrap } from './BlogPostImage.module.css';

export default function BlogPostImage({ alt, src, ...props }) {
  if (/\/\/images\.ctfassets\.net/.test(src)) {
    return <img {...props} alt={alt} src={src} />;
  }
  return (
    <div className={wrap}>
      <img {...props} alt={alt} className={img} src={src} />
    </div>
  );
}

BlogPostImage.propTypes = {
  alt : PropTypes.string,
  src : PropTypes.string.isRequired
};

BlogPostImage.defaultProps = {
  alt : ''
};
