import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { injectInstagramEmbedScript } from '../utils/vendor-scripts/injectInstagramEmbedScript';
import { wrap } from './BlogPostInstagramEmbed.module.css';
import BlogPostSocialEmbedWrap from './BlogPostSocialEmbedWrap';

export default function BlogPostInstagramEmbedShortCode({ code }) {
  const [instagramEmbed, setinstagramEmbed] = useState('');

  useEffect(() => {
    async function fetchInstagramEmbed() {
      const response = await fetch(`https://api.instagram.com/oembed?url=http://instagr.am/p/${code}/&OMITSCRIPT=true`);
      const data = await response.json();

      setinstagramEmbed(data.html);
      injectInstagramEmbedScript();
    }

    fetchInstagramEmbed();
  }, [code]);

  return (
    <BlogPostSocialEmbedWrap>
      {/* eslint-disable-next-line react/no-danger */}
      <span className={`babs-instagram-media ${wrap}`} dangerouslySetInnerHTML={{ __html : instagramEmbed }} />
    </BlogPostSocialEmbedWrap>
  );
}

BlogPostInstagramEmbedShortCode.propTypes = {
  code : PropTypes.string.isRequired
};
