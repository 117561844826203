import { instagramEmbedRegex, socialMediaEmbedRegex, videoEmbedRegex } from '../constants';

function isEmbed(element) {
  const { props } = element;
  return props && props.children && videoEmbedRegex.test(props.children);
}

function isPostImage(element) {
  const { props } = element;
  return props && props.alt !== undefined && props.src;
}

function isSocialMediaEmbed(element) {
  const { props } = element;
  return props && props.children && (socialMediaEmbedRegex.test(props.children) || instagramEmbedRegex.test(props.children));
}

/**
 * Checks to see if passed in children have been wrapped with
 * embeded video component or post image component
 *
 * @method isBlockElement
 *
 * @param {Array} children React elements
 *
 * @returns {Boolean} Are the passed in children wrapped in embeded video component or post image component
 */
export default function isBlockElement(children) {
  return children && children.length && (isPostImage(children[0]) || isEmbed(children[0]) || isSocialMediaEmbed(children[0]));
}
