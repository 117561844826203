import PropTypes from 'prop-types';
import React from 'react';

import { instagramEmbedRegex, videoEmbedRegex } from '../constants';
import BlogPostInstagramEmbed from './BlogPostInstagramEmbed';
import BlogPostInstagramEmbedShortCode from './BlogPostInstagramEmbedShortCode';
import EmbedVideoPlayer from './EmbedVideoPlayer';

export default function BlogPostCode({ children, ...props }) {
  const videoEmbedUrl = children[0].split(videoEmbedRegex)[1];
  if (videoEmbedUrl) {
    return <EmbedVideoPlayer url={videoEmbedUrl} />;
  }
  if (instagramEmbedRegex.test(children)) {
    const instagramEmbedCode = children[0].split(instagramEmbedRegex)[1];
    return <BlogPostInstagramEmbedShortCode code={instagramEmbedCode} />;
  }
  if (/instagram-media/.test(children)) {
    return (
      <BlogPostInstagramEmbed>
        {children}
      </BlogPostInstagramEmbed>
    );
  }
  return <code {...props}>{children}</code>;
}

BlogPostCode.propTypes = {
  children : PropTypes.node.isRequired
};
