import { CTAButton } from '@animoto/components';
import PropTypes from 'prop-types';
import React from 'react';

import { wrap } from './BlogPostCTAButton.module.css';

export default function BlogPostCTAButton({ children, ...props }) {
  return (
    <div className={wrap}>
      <CTAButton {...props}>{children}</CTAButton>
    </div>
  );
}

BlogPostCTAButton.propTypes = {
  children : PropTypes.node.isRequired
};
