import { ArticlePreview, CuratedArticlesList } from '@animoto/components';
import PropTypes from 'prop-types';
import React from 'react';

export default function RelatedPosts({ posts }) {
  return (
    <CuratedArticlesList title="Related Articles">
      {posts.map(({ node }) => {
        const {
          body,
          description,
          featuredImage,
          id,
          mainCategory,
          title
        } = node;

        return (
          <ArticlePreview
            key={id}
            category={(mainCategory && mainCategory.name) || ''}
            categoryUrl={(mainCategory && mainCategory.fields && `/blog/category/${mainCategory.fields.categorySlug}`) || ''}
            imageSrcFluid={featuredImage && featuredImage.fluid}
            postUrl={`/blog/${node.fields.postSlug}`}
            title={title}
          >
            {(description && description.childMarkdownRemark.excerpt) || (body && body.childMarkdownRemark.excerpt)}
          </ArticlePreview>
        );
      })}
    </CuratedArticlesList>

  );
}

RelatedPosts.propTypes = {
  posts : PropTypes.arrayOf(PropTypes.shape({
    node : PropTypes.shape({
      body : PropTypes.shape({
        childMarkdownRemark : PropTypes.shape({
          excerpt : PropTypes.string
        })
      }),
      featuredImage : PropTypes.shape({
        fixed : PropTypes.shape({
          src : PropTypes.string
        })
      }),
      fields : PropTypes.shape({
        postSlug : PropTypes.string
      }),
      id           : PropTypes.string,
      mainCategory : PropTypes.shape({
        fields : PropTypes.shape({
          categorySlug : PropTypes.string
        }),
        name : PropTypes.string
      }),
      retinaFeaturedImage : PropTypes.shape({
        fixed : PropTypes.shape({
          src : PropTypes.string
        })
      }),
      title     : PropTypes.string,
      updatedAt : PropTypes.string
    })
  })).isRequired
};
