import { Children } from 'react';
/**
 * Looks for a heading id from markdown in the children
 * and returns the id and the children with the id removed
 * Example heading id "What is video marketing? {#what}"
 *
 * @param {String} id The default heading id from remark
 * @param {Array} children The elements children
 *
 * @returns {Object} The heading id and children with the id stripped
 */
export default function getHeadingId(id, children) {
  let headingId = id;
  const childrenArray = Children.toArray(children);
  const childrenWithoutHeadingHash = childrenArray.map((child) => {
    if (typeof child === 'string') {
      const hasAnchor = child.match(/.*({#(.+)})/);
      if (hasAnchor) {
        const [, hash, anchor] = hasAnchor;
        headingId = anchor;
        return child.replace(hash, '');
      }
    }
    return child;
  });

  return {
    headingId,
    children : childrenWithoutHeadingHash
  };
}
