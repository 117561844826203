import { Paragraph } from '@animoto/components';
import PropTypes from 'prop-types';
import React from 'react';

import isBlockElement from '../utils/isBlockElement';
import isNotParagraphWrappedElement from '../utils/isNotParagraphWrappedElement';

export default function BlogPostParagraph({ children }) {
  if (isNotParagraphWrappedElement(children)) {
    return children;
  }
  const element = isBlockElement(children) ? 'div' : 'p';
  return <Paragraph element={element} withSpacing>{children}</Paragraph>;
}

BlogPostParagraph.propTypes = {
  children : PropTypes.node.isRequired
};
