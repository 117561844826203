import PropTypes from 'prop-types';
import React from 'react';

export default function BlogPostSchemaTag({ children }) {
  return (
    <span>{children}</span>
  );
}

BlogPostSchemaTag.propTypes = {
  children : PropTypes.node.isRequired
};
