import PropTypes from 'prop-types';
import React from 'react';

import { wrap } from './BlogPostSocialEmbedWrap.module.css';

export default function BlogPostSocialEmbedWrap({ children }) {
  return (
    <div className={wrap}>
      {children}
    </div>
  );
}

BlogPostSocialEmbedWrap.propTypes = {
  children : PropTypes.node.isRequired
};
