import PropTypes from 'prop-types';
import React from 'react';

import { ul } from './BlogPostUl.module.css';

export default function BlogPostUl({ children }) {
  return <ul className={ul}>{children}</ul>;
}

BlogPostUl.propTypes = {
  children : PropTypes.node.isRequired
};
