import {
  ArticleHeader,
  ArticleTags,
  InlineLink
} from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import requiredIf from 'react-required-if';
import MediaQuery from 'react-responsive';

import AnimotoBlogLayout from '../../components/AnimotoBlogLayout';
import BlogMarkdownBody from '../../components/BlogMarkdownBody';
import RecentPostsSidebar from '../../components/RecentPostsSidebar';
import RelatedPosts from '../../components/RelatedPosts';
import generateHowToSchema from '../../utils/generateHowToSchema';
import generateListSchema from '../../utils/generateListSchema';
import {
  body,
  header,
  sidebar,
  wrap
} from '../blogContentCommon.module.css';
import {
  divider,
  relatedArticles,
  relatedArticlesInner
} from './BlogPost.module.css';
/* eslint-enable no-unused-vars */

export default function BlogPost({ data }) {
  const { post, relatedPosts, site } = data;
  const articleUrl = `${site.siteMetadata.blog.url}/${post.fields.postSlug}`;
  const { canonicalUrlPage, schemaType, tags } = post;
  const image = post.featuredImage ? post.featuredImage.resize.src : null;
  const SEOInfo = {
    author        : post.author,
    canonicalSlug : `/blog/${canonicalUrlPage && canonicalUrlPage.fields.postSlug}`,
    description   : (post.metaDescription && post.metaDescription.metaDescription) || (post.description && post.description.description),
    image,
    noIndex       : post.noIndex,
    noIndexOnBlog : post.noIndexOnBlog,
    publishedOn   : post.publishedOn,
    slug          : `blog/${post.fields.postSlug}`,
    title         : post.metaTitle || post.title,
    type          : 'blogPost',
    updatedAt     : post.updatedAt
  };

  if (schemaType === 'list') {
    const schema = generateListSchema(post);
    if (schema) {
      SEOInfo.listSchema = schema;
    }
  }

  if (schemaType === 'how-to') {
    const schema = generateHowToSchema(post);
    if (schema) {
      SEOInfo.howToSchema = schema;
    }
  }

  return (
    <AnimotoBlogLayout SEOInfo={SEOInfo}>
      <div className={wrap}>
        <ArticleHeader
          articleUrl={articleUrl}
          author={post.author.name}
          authorUrl={`/blog/author/${post.author.slug}`}
          breadcrumb={post.mainCategory.name}
          breadcrumbUrl={`/blog/category/${post.mainCategory.fields.categorySlug}`}
          className={header}
          publishDate={post.publishedOn || post.updatedAt}
          title={post.title}
        />
        <div className={body}>
          <BlogMarkdownBody>
            {post.body.childMarkdownRemark.htmlAst}
          </BlogMarkdownBody>
          { tags && (
            <>
              <div className={divider} />
              <ArticleTags>
                {tags.map(({ name, slug }) => (
                  <InlineLink
                    key={slug}
                    href={`/blog/tag/${slug}`}
                  >
                    {name}
                  </InlineLink>
                ))}
              </ArticleTags>
            </>
          )}
        </div>
        <MediaQuery minWidth={768}>
          <RecentPostsSidebar className={sidebar} />
        </MediaQuery>
      </div>
      {relatedPosts && (
        <aside className={relatedArticles}>
          <div className={relatedArticlesInner}>
            <RelatedPosts posts={relatedPosts.edges} />
          </div>
        </aside>
      )}
    </AnimotoBlogLayout>
  );
}

export const pageQuery = graphql`
  query BlogPostByUrl($postIds: [String!]!, $slug: String!) {
    site: site {
      siteMetadata {
        siteUrl
        blog {
          url
        }
      }
    }
    post: contentfulBlogPost(slug: { eq: $slug }) {
      schemaType
      author {
        name
        slug
      }
      canonicalUrlPage {
        fields {
          postSlug
        }
      }
      body {
        childMarkdownRemark {
          htmlAst
          excerpt
        }
      }
      featuredImage {
        resize(width: 1800) {
          src
        }
      }
      mainCategory {
        name
        fields {
          categorySlug
        }
      }
      id
      publishedOn
      updatedAt
      metaTitle
      title
      noIndex
      noIndexOnBlog
      fields {
        postSlug
      }
      tags {
        name
        slug
      }
      description {
        description
      }
      metaDescription {
        metaDescription
      }
      howToDescription {
        howToDescription
      }
      howToTools
      howToSupplies
      howToTotalTime
    }
    relatedPosts: allContentfulBlogPost(
    filter: { 
      id: {in: $postIds},
      noIndexOnBlog: { ne: true }, 
    }, 
    sort: {
      fields : [publishedOn], order :DESC
    }) {
      edges {
        node {
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          description {
            childMarkdownRemark {
              excerpt
            }
          }
          featuredImage {
            fluid(maxWidth: 670, maxHeight: 447) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          fields {
            postSlug
          }
          id
          mainCategory {
            name
            fields {
              categorySlug
            }
          }
          title
        }
      }
    }
  }
`;

BlogPost.propTypes = {
  data : PropTypes.shape({
    post : PropTypes.shape({
      author : PropTypes.shape({
        name : PropTypes.string,
        slug : PropTypes.string
      }),
      body : PropTypes.shape({
        childMarkdownRemark : PropTypes.shape({
          excerpt : PropTypes.string,
          htmlAst : PropTypes.object
        })
      }),
      canonicalUrlPage : PropTypes.shape({
        fields : PropTypes.shape({
          postSlug : PropTypes.string
        })
      }),
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      featuredImage : PropTypes.shape({
        resize : PropTypes.shape({
          src : PropTypes.string
        })
      }),
      fields : PropTypes.shape({
        postSlug : PropTypes.string
      }),
      howToDescription : requiredIf(PropTypes.shape({
        howToDescription : PropTypes.string
      }), (props) => props.schemaType === 'how-to'),
      howToSupplies  : requiredIf(PropTypes.string, (props) => props.schemaType === 'how-to'),
      howToTools     : requiredIf(PropTypes.string, (props) => props.schemaType === 'how-to'),
      howToTotalTime : requiredIf(PropTypes.string, (props) => props.schemaType === 'how-to'),
      id             : PropTypes.string,
      mainCategory   : PropTypes.shape({
        fields : PropTypes.shape({
          categorySlug : PropTypes.string
        }),
        name : PropTypes.string
      }),
      metaDescription : PropTypes.shape({
        metaDescription : PropTypes.string
      }),
      metaTitle     : PropTypes.string,
      noIndex       : PropTypes.bool,
      noIndexOnBlog : PropTypes.bool,
      publishedOn   : PropTypes.string,
      schemaType    : PropTypes.oneOf(['how-to', 'list']),
      tags          : PropTypes.arrayOf(
        PropTypes.shape({
          name : PropTypes.string,
          slug : PropTypes.string
        })
      ),
      title     : PropTypes.string,
      updatedAt : PropTypes.string
    }),
    relatedPosts : PropTypes.shape({
      edges : PropTypes.arrayOf(
        PropTypes.shape({
          node : PropTypes.shape({
            body : PropTypes.shape({
              childMarkdownRemark : PropTypes.shape({
                excerpt : PropTypes.string
              })
            }),
            description : PropTypes.shape({
              childMarkdownRemark : PropTypes.shape({
                excerpt : PropTypes.string
              })
            }),
            featuredImage : PropTypes.shape({
              fluid : PropTypes.shape({
                aspectRatio : PropTypes.number,
                sizes       : PropTypes.string,
                src         : PropTypes.string,
                srcSet      : PropTypes.string,
                srcSetWebp  : PropTypes.string,
                srcWebp     : PropTypes.string
              })
            }),
            fields : PropTypes.shape({
              postSlug : PropTypes.string
            }),
            id           : PropTypes.string,
            mainCategory : PropTypes.shape({
              fields : PropTypes.shape({
                categorySlug : PropTypes.string
              }),
              name : PropTypes.string
            }),
            title : PropTypes.string
          })
        })
      )
    }),
    site : PropTypes.shape({
      siteMetadata : PropTypes.shape({
        blog : PropTypes.shape({
          url : PropTypes.string
        })
      })
    })
  }).isRequired
};
