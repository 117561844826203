import PropTypes from 'prop-types';
import React from 'react';

import { wrap } from './BlogPostInstagramEmbed.module.css';
import BlogPostSocialEmbedWrap from './BlogPostSocialEmbedWrap';

export default function BlogPostInstagramEmbed({ children }) {
  return (
    <BlogPostSocialEmbedWrap>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={wrap} dangerouslySetInnerHTML={{ __html : children }} />
    </BlogPostSocialEmbedWrap>
  );
}

BlogPostInstagramEmbed.propTypes = {
  children : PropTypes.node.isRequired
};
