import { Label, SectionTitle } from '@animoto/components';
import PropTypes from 'prop-types';
import React from 'react';

import getHeadingId from '../utils/getHeadingId';

export default function BlogPostHeading({ element, children, id, ...props }) {
  const { headingId = id, children: headerChildern } = getHeadingId(id, children);
  const useLabel = ['h3', 'h4'].includes(element);

  return (useLabel ? (
    <Label element={element} id={headingId} withSpacing {...props}>{headerChildern}</Label>
  ) : (
    <SectionTitle element={element} id={headingId} withSpacing {...props}>{headerChildern}</SectionTitle>
  ));
}

BlogPostHeading.propTypes = {
  children : PropTypes.node.isRequired,
  element  : PropTypes.string,
  id       : PropTypes.string
};

BlogPostHeading.defaultProps = {
  element : 'h1',
  id      : null
};
