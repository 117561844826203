import { BoldCopy, InlineLink } from '@animoto/components';
import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import RehypeReact from 'rehype-react';

import BlogPostBlockQuote from './BlogPostBlockQuote';
import BlogPostCode from './BlogPostCode';
import BlogPostCTAButton from './BlogPostCTAButton';
import BlogPostHeading from './BlogPostHeading';
import BlogPostIframe from './BlogPostIframe';
import BlogPostImage from './BlogPostImage';
import BlogPostParagraph from './BlogPostParagraph';
import BlogPostPre from './BlogPostPre';
import BlogPostSchemaTag from './BlogPostSchemaTag';
import BlogPostTextArea from './BlogPostTextArea';
import BlogPostUl from './BlogPostUl';

function headingFactory(elementType) {
  return ({ children, ...props }) => ( // eslint-disable-line react/prop-types
    <BlogPostHeading {...props} element={elementType}>{children}</BlogPostHeading>
  );
}

const renderAst = new RehypeReact({
  createElement,
  components : {
    p          : BlogPostParagraph,
    a          : InlineLink,
    h1         : BlogPostHeading,
    h2         : headingFactory('h2'),
    h3         : headingFactory('h3'),
    h4         : headingFactory('h4'),
    iframe     : BlogPostIframe,
    img        : BlogPostImage,
    pre        : BlogPostPre,
    code       : BlogPostCode,
    textarea   : BlogPostTextArea,
    ctabutton  : BlogPostCTAButton,
    blockquote : BlogPostBlockQuote,
    strong     : BoldCopy,
    ul         : BlogPostUl,
    schematag  : BlogPostSchemaTag
  }
}).Compiler;

export default function BlogMarkdownBody({ children }) {
  return renderAst(children);
}

BlogMarkdownBody.propTypes = {
  children : PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};
