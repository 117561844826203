import PropTypes from 'prop-types';
import React from 'react';

import BlogPostSocialEmbedWrap from './BlogPostSocialEmbedWrap';

function isTwitterMediaEmbedBlockQoute({ className }) {
  return className === 'twitter-tweet';
}

export default function BlogPostBlockQuote({ children, ...props }) {
  const blockqouteBody = (
    <blockquote {...props}>
      {children}
    </blockquote>
  );
  if (isTwitterMediaEmbedBlockQoute(props)) {
    return (
      <BlogPostSocialEmbedWrap>
        {blockqouteBody}
      </BlogPostSocialEmbedWrap>
    );
  }

  return blockqouteBody;
}

BlogPostBlockQuote.propTypes = {
  children : PropTypes.node.isRequired
};
