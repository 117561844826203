import PropTypes from 'prop-types';
import React from 'react';

import BlogPostSocialEmbedWrap from './BlogPostSocialEmbedWrap';

export default function BlogPostIframe({ title, src, allowTransparency, allowFullScreen, ...props }) {
  /* eslint-disable react/no-unknown-property */
  const iframe = (
    <iframe
      allowFullScreen={allowFullScreen === 'true'}
      allowtransparency={allowTransparency}
      src={src}
      title={title}
      {...props}
    />
  );
  /* eslint-enable react/no-unknown-property */

  if (/facebook\.com\/plugins/.test(src)) {
    return (
      <BlogPostSocialEmbedWrap>
        {iframe}
      </BlogPostSocialEmbedWrap>
    );
  }

  return iframe;
}

BlogPostIframe.propTypes = {
  allowFullScreen   : PropTypes.string,
  allowTransparency : PropTypes.string,
  src               : PropTypes.string.isRequired,
  title             : PropTypes.string
};

BlogPostIframe.defaultProps = {
  allowTransparency : '',
  allowFullScreen   : '',
  title             : ''
};
