import { selectAll } from 'hast-util-select';

import parseSchemaList from './parseSchemaList';

/**
 * Checks the schematag for the HowTo name or step text and adds to schema object
 *
 * @method generateHowToSchema
 *
 * @param {object} post json representation of the blog post
 *
 * @returns {object} SEO info needed for setting the json-ld schema
 */

export default function generateHowToSchema(post) {
  const { htmlAst } = post.body.childMarkdownRemark;
  const { howToSupplies, howToTools } = post;
  const schemaTags = selectAll('schematag', htmlAst);
  const postDescription = (post.description && post.description.description) || post.body.childMarkdownRemark.excerpt;

  let howToSchema = {
    '@context'  : 'http://schema.org',
    '@type'     : 'HowTo',
    name        : post.title,
    description : (post.howToDescription && post.howToDescription.howToDescription) || postDescription,
    step        : [],
    tool        : (howToTools && parseSchemaList('HowToTool', howToTools)) || null,
    supply      : (howToSupplies && parseSchemaList('HowToSupply', howToSupplies)) || null,
    totalTime   : post.howToTotalTime
  };

  schemaTags.forEach((tag) => {
    const { properties } = tag;
    const tagType = properties.type;

    if (tagType === 'how-to-name') {
      howToSchema.name = tag.children[0].value;
    } else if (tagType === 'how-to-step') {
      const text = tag.children[0].value;
      const {
        name,
        image,
        videoname,
        videodescription,
        videothumbnail,
        videouploaddate,
        videoduration,
        videocontenturl,
        videoembedurl,
        url
      } = properties;
      const video = videocontenturl && videoname && videodescription && videothumbnail && videouploaddate && videoduration;
      let stepInfo = {};

      if (name && text && (image || video) && url) {
        stepInfo = {
          '@type' : 'HowToStep',
          name,
          text,
          url
        };
        if (image) {
          stepInfo.image = image;
        }
        if (video) {
          stepInfo.video = {
            '@type'      : 'VideoObject',
            name         : videoname,
            description  : videodescription,
            thumbnailUrl : videothumbnail,
            uploadDate   : videouploaddate,
            duration     : videoduration,
            contentUrl   : videocontenturl,
            embedUrl     : videoembedurl
          };
        }
        howToSchema.step.push(stepInfo);
      }
    }
  });

  howToSchema = howToSchema.step.length > 1 ? howToSchema : null;

  return howToSchema;
}
