// extracted by mini-css-extract-plugin
export var base = "blogContentCommon-module--base--235zd";
export var rightAlignedContainer = "blogContentCommon-module--rightAlignedContainer--1cIxi";
export var rightAlignedContainerSmall = "blogContentCommon-module--rightAlignedContainerSmall--3f72i";
export var leftAlignedContainer = "blogContentCommon-module--leftAlignedContainer--20JMs";
export var blogContainer = "blogContentCommon-module--blogContainer--1QQgl blogContentCommon-module--base--235zd";
export var container = "blogContentCommon-module--container--1zxQp blogContentCommon-module--base--235zd";
export var largeContainer = "blogContentCommon-module--largeContainer--3NVsc blogContentCommon-module--base--235zd";
export var mobileSmallPadding = "blogContentCommon-module--mobileSmallPadding--2iszB";
export var fullWidthMobile = "blogContentCommon-module--fullWidthMobile--2vGS8";
export var gridWithSidebar = "blogContentCommon-module--gridWithSidebar--2RYPB";
export var noMaxWidth = "blogContentCommon-module--noMaxWidth--2bFLv";
export var wrap = "blogContentCommon-module--wrap--1-rin blogContentCommon-module--blogContainer--1QQgl blogContentCommon-module--base--235zd blogContentCommon-module--gridWithSidebar--2RYPB";
export var header = "blogContentCommon-module--header--WpuAX";
export var body = "blogContentCommon-module--body--arFN1";
export var sidebar = "blogContentCommon-module--sidebar--xHv0N";